import { HTTP } from "@/libs/https.js"
import config from "@/libs/config.js"

class SummaryParentModel extends HTTP {
    // 导入家长
    importParentInfo(data) {
        return new Promise((resolve, reject) => {
            this.axiosFile({
                url: config.API.IMPORT_PARENT_INFO,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }
    // 获取班级
    getTreeBySchoolId(data) {
        return new Promise((resolve, reject) => {
            this.axiosFile({
                url: config.API.GET_TREE_BY_SCHOOL_ID,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    // 获取学生
    getStuListByOrganId(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_STU_LIST_BY_ORGAN_ID,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    // 获取学生
    getOrganTreeBySchoolId(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_ORGAN_TREE_BY_SCHOOL_ID,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
}

export { SummaryParentModel }
