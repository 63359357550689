import { HTTP } from '@/libs/https.js';
import config from '@/libs/config.js';

class SystemPersonnelModel extends HTTP {
    getDepartmentList(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_DEPARTMENT_LIST,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    getSubjectList(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SUBJECT_LIST,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    getAllDepartmentList(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_ALL_DEPARTMENT_LIST,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    getConditionList(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_CONDITION_LIST,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    setSwitchingUserStatus(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SET_SWITCHING_USER_STATUS,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    getSchoolUserInfo(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SCHOOL_USER_INFO,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }
}

export { SystemPersonnelModel };
