import { HTTP } from '@/libs/https.js';
import config from '@/libs/config.js';

class SummaryTeacherBase extends HTTP {
    getSelectFileCategory(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SELECT_FILE_CATEGORY,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    schoolArchivesDesign (data){
        return new Promise((resolve, reject) => {
            this.axiosFile({
                url: config.API.SCHOOL_ARCHIVES_DESIGN,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    getDesignList (data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_DESIGN_LIST,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    getUserPreferences (data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_USER_PREFERENCES,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    saveUserPreferences (data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SAVE_USER_PREFERENCES,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    teacherUpdateImport (data) {
        return new Promise((resolve, reject) => {
            this.axiosFile({
                url: config.API.TEACHER_UPDATE_IMPORT,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }
}

export { SummaryTeacherBase };
