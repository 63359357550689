/*
 * @Author: 张冰华 690227436@qq.com
 * @Date: 2023-12-12 15:10:45
 * @LastEditors: 张冰华 690227436@qq.com
 * @LastEditTime: 2023-12-12 15:12:19
 * @FilePath: \school_end\src\models\SummaryStudentBase.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { HTTP } from '@/libs/https.js';
import config from '@/libs/config.js';

class SummaryStudentBase extends HTTP {

    // 学生档案-信息更新
    studentUpdateImport(data) {
        return new Promise((resolve, reject) => {
            this.axiosFile({
                url: '/api/operate/operateStudent/studentUpdateImport',
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    getUserPreferences(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_USER_PREFERENCES,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    saveUserPreferences(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SAVE_USER_PREFERENCES,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }
}

export { SummaryStudentBase };
