import { HTTP } from '@/libs/https.js';
import config from '@/libs/config.js';

class SystemModel extends HTTP {
    // roleList (data) {
    //     return new Promise((resolve, reject) => {
    //         this.axiosPost({
    //             url: config.API.GET_ROLE_LIST,
    //             data: data,
    //             header: 'application/json',
    //             success (data) {
    //                 resolve({
    //                     data,
    //                     status: 0
    //                 });
    //             },
    //             error (error) {
    //                 resolve({
    //                     error,
    //                     status: - 1
    //                 })
    //             }
    //         });
    //     });
    // };
getStoreList(data){
    return new Promise((resolve, reject) => {
        this.axiosPost({
            url: config.API.GET_STORE_LIST,
            data: data,
            header: 'application/json',
            success (data) {
                resolve({
                    data,
                    status: 0
                });
            },
            error (error) {
                resolve({
                    error,
                    status: - 1
                })
            }
        });
    });
}

getDeptList(data){
    return new Promise((resolve, reject) => {
        this.axiosPost({
            url: config.API.GET_DEPT_LIST,
            data: data,
            header: 'application/json',
            success (data) {
                resolve({
                    data,
                    status: 0
                });
            },
            error (error) {
                resolve({
                    error,
                    status: - 1
                })
            }
        });
    });
}

getGoodsList(data){
    return new Promise((resolve, reject) => {
        this.axiosPost({
            url: config.API.GET_GOODS_LIST,
            data: data,
            header: 'application/json',
            success (data) {
                resolve({
                    data,
                    status: 0
                });
            },
            error (error) {
                resolve({
                    error,
                    status: - 1
                })
            }
        });
    });
}
saveGoods(data){
    return new Promise((resolve, reject) => {
        this.axiosPost({
            url: config.API.SAVE_GOODS,
            data: data,
            header: 'application/json',
            success (data) {
                resolve({
                    data,
                    status: 0
                });
            },
            error (error) {
                resolve({
                    error,
                    status: - 1
                })
            }
        });
    });
}

deleteGoods(data){
    return new Promise((resolve, reject) => {
        this.axiosPostArray({
            url: config.API.DELETE_GOODS,
            data: data,
            header: 'application/json',
            success (data) {
                resolve({
                    data,
                    status: 0
                });
            },
            error (error) {
                resolve({
                    error,
                    status: - 1
                })
            }
        });
    });
}

  uploadFile (data) {
    return new Promise((resolve, reject) => {
      this.axiosFile({
        url: config.API.UPLOAD_FILE,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  };

    userList (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_USER_LIST,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    };

    getOrganizationalStructureList (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_ORGANIZATIONAL_STRUCTURE_LIST,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    };

    addOrganizationalStructureSector (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.ADD_ORGANIZATIONAL_STRUCTURE_SECTOR,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    };

    editOrganizationalStructureSector (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.EDIT_ORGANIZATIONAL_STRUCTURE_SECTOR,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    };

    delOrganizationalStructureSector (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.DEL_ORGANIZATIONAL_STRUCTURE_SECTOR,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    };

    getRoleList (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_ROLE_LIST,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    };

    roleSave (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.ROLE_SAVE,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    };

    getMenusTree (data) {
        return new Promise((resolve, reject) => {
            this.axiosGet({
                url: config.API.GET_MENUS_TREE + `?belongPlat=1`,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    };
  delDict (data) {
    return new Promise((resolve, reject) => {
      this.axiosPostArray({
        url: config.API.DEL_DICT,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  };
    delRole (data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.DEL_ROLE,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    };
    editRole (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.ROLE_EDIT,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    };

    resetPassword(data) {
        return new Promise((resolve, reject) => {
            this.axiosGET({
                url: config.API.RESET_PASSWORD,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };

    userSave(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.USER_SAVE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    userEdit(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.USER_EDIT,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    userList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.USER_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    userResetPassword(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.USER_RESET,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    userModifyPassword(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.USER_MODIFY,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    userDel(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.USER_DEL,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    getRoleDropDown (data) {
        return new Promise((resolve, reject) => {
            this.axiosGet({
                url: config.API.GET_ROLE_DROP_DOWN,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    };

}

export { SystemModel };
