import { HTTP } from "@/libs/https.js"
import config from "@/libs/config.js"

class SystemOrganizationalStuStructure extends HTTP {
    // 导入学生架构
    importOrganInfo(data) {
        return new Promise((resolve, reject) => {
            this.axiosFile({
                url: config.API.IMPORT_ORGAN_INFO,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }
}

export { SystemOrganizationalStuStructure }
